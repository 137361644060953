.title-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.thread-button {
  height: 50px;
  width: 90px;
  border-radius: 5px;
  cursor: pointer;
}

.images {
  height: 180px;
  width: 200px;
  object-fit: contain;
}

.text-area {
  width: -webkit-fill-available;
  border: 1px solid gray;
  border-radius: 5px;
  padding-top: 20px;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .thread-status {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 1;
  }
  .thread-ticket {
    display: flex;
    justify-content: left;
  }
}

.attachment-send-button {
  margin-left: 20px !important;
  margin-bottom: 5px !important;
}

@media only screen and (max-width: 600px) {
  .text-box-grid {
    margin-right: 20px !important;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
    margin-right: 20px !important;
  }
}

.support-Profile-image {
  height: 40px;
  width: 40px;
  border: 1px solid;
  border-radius: 22px;
  margin: 10px 0 10px 0;
}

.admin-messages {
  justify-content: flex-end;
  width: 70%;
  justify-content: end;
  float: right;
  text-align: end;
  align-items: flex-start;
  background-color: rgba(255, 250, 193, 1);
  border-right: 1px solid gray;
  border-left: none;
  margin-right: 20px;
}
.customer-messages {
  justify-content: flex-start;
  width: 70%;
}
.user-reply-card {
  width: 70%;
}
.admin-reply-card {
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 70%;
}
.admin-reply-card-1 {
  justify-content: flex-end;
}

.input-support-thread .react-input-emoji--input {
  height: 120px;
}

.input-support-thread  .react-input-emoji--input {
  height: 120px;
}

.input-support-thread  .react-emoji-picker--wrapper {
  right: -355px !important;
  top: 5px !important;
  overflow: visible !important;
}

.input-support-thread .react-input-emoji--button{
  margin-top: 70px !important;
}


 .input-support-thread .MuiCardContent-root .css-46bh2p-MuiCardContent-root{
      margin: 20px 0 90px 0 !important;
}

.padding .padding-lr{
  display: none !important;
}
