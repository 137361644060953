@media only screen and (max-width: 1280px) {
  .card-grid {
    justify-content: center;
    display: flex;
  }
}

@media only screen and (min-width: 1600px) {
  .card-grid {
    margin-left: 11px;
  }
}

.animated-container {
  margin: 0 0 0 8px;
}

.makeStyles-title-14 {
  text-align: center;
}

.makeStyles-title-7 {
  text-align: center;
}
