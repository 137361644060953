.icons-edit:hover {
  background-color: rgb(73, 164, 238);
  color: aliceblue;
}

.icons-delete:hover {
  background-color: rgb(245, 72, 72);
  color: aliceblue;
}
.icons-details:hover {
  background-color: rgb(0, 0, 0);
  color: aliceblue;
}

.table-data-row:hover {
  background-color: rgb(241, 241, 241);
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 500px) {
  .style-box {
    display: flex;
    justify-content: right;
  }
}

.style-box {
  position: sticky;
  top: 90px;
  z-index: 99;
  padding-bottom: 20px;
}

.Profile-image {
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 25px;
}

.ingredient-title {
  color: black;
}
