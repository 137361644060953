.avatar {
  width: 40px;
  height: 40px;
  font-size: larger;
  margin-top: 8px;
}

.post-text-ellipsis {
  white-space: nowrap; 
  width: 50px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


.post-notes {
  -webkit-line-clamp: 2;
  max-height: 60px;
  letter-spacing: 1px;
}
.ellipse-question{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
padding-right: 20px;

} 
.ellipse-id{
  text-overflow: ellipsis;
    overflow: hidden;
    width: 60px;
}

.title-post {
  -webkit-line-clamp: 1;
}
.sort-order.active {
  background-color: rgb(255, 225, 53, 0.6);
}
.sort-order:hover {
  background-color: #ffffff;
}

.card-flag-image {
  border-radius: 100px;
  height: 26px;
  width: 26px;
  vertical-align: middle;
  border: 1px solid black;
}

.image-grid .css-8oi9w1-MuiGrid-root {
  padding-left: 0px !important;
}

.date-picker {
  padding: 12px;
  border-radius: 7px;
  border: darkgrey solid 1px;
}

.react-datepicker__close-icon::after {
  color: black !important;
  background-color: rgb(255, 225, 53) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgb(255, 225, 53) !important;
  font-weight: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgb(255, 225, 53) !important;
  color: black !important;
}

@media only screen and (max-width: 500px) {
  .menu {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .date-category {
    display: flex;
    justify-content: space-between;
    gap: 4px;
  }
  .image-grid {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .card-flag-image {
    height: 25px;
    width: 25px;
  }
}

.css-zow5z4-MuiGrid-root > .MuiGrid-item {
  padding: 12px 12px 12px 12px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 7px;
  left: 6px;
  padding: 0rem !important;
}

.export-button {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid gray;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.asc-des {
  height: 27px;
  width: 27px;
}

.post-card-Profile-image {
  height: 40px;
  width: 40px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 22px;
}
