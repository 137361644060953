.button {
  background-color: #44564a;
  color: #ffffff;
  cursor: "pointer";
}

.button:hover {
  background-color: #5d7264;
  color: #ffffff;
  cursor: "pointer";
}

#history .css-pakv0j-MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root {
  border-bottom:1px solid;
}


.historyImage{
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 25px;
}